<template>
  <span
    :role="length >= max ? 'alert' : null"
    class="tw-mb-2 tw-text-xs"
    :class="{
      'tw-text-warning-500': length < max && length >= max * 0.9,
      'tw-text-danger-500': length >= max,
    }">
    {{ length }} / {{ max }}
  </span>
</template>

<script>
export default {
  name: 'MaxLengthIndicator',
  props: {
    value: {
      type: String,
      default: null,
    },

    max: {
      type: Number,
      required: true,
    },
  },

  computed: {
    length() {
      return this.value?.length ?? 0;
    },
  },
};
</script>
