<template>
  <div class="tw-flex tw-flex-col tw-mb-3">
    <BFormTextarea
      v-model="mutableValue"
      :maxlength="maxLength"
      :disabled="loading"
      class="tw-mb-2" />
    <MaxLengthIndicator
      v-if="maxLength"
      v-model="mutableValue"
      class="tw-ml-auto"
      :max="maxLength" />
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import MaxLengthIndicator from '@/components/forms/MaxLengthIndicator';

export default {
  name: 'CTextArea',
  components: {
    BFormTextarea,
    MaxLengthIndicator,
  },

  props: {
    loading: {
      type: Boolean,
    },

    value: {
      type: String,
      default: null,
    },

    maxLength: {
      type: Number,
      default: null,
    },
  },

  emits: ['input'],

  computed: {
    mutableValue: {
      get() {
        return this.value;
      },

      set(mutableValue) {
        this.$emit('input', mutableValue);
      },
    },
  },
};
</script>
