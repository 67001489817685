<template>
  <BFormCheckboxGroup
    class="skeleton"
    disabled
    v-bind="{ ...$attrs, ...$props }"
    :options="placeholderOptions" />
</template>

<script>
import { BFormCheckboxGroup } from 'bootstrap-vue';

const OPTION_CONTENT = {
  html: '<span style=\'width: 28ch;\' class=\'skeleton skeleton--inline\'><span>&nbsp;</span></span>',
};

export default {
  name: 'FormCheckboxGroupSkeleton',
  components: {
    BFormCheckboxGroup,
  },

  extends: BFormCheckboxGroup,
  props: {
    amount: {
      type: Number,
      default: () => 2,
    },
  },

  computed: {
    placeholderOptions() {
      return Array.from({ length: this.amount }).map(() => OPTION_CONTENT);
    },
  },
};
</script>
