<template>
  <Card v-test="'returns-reasons'">
    <Heading level="2">
      {{ $t('returns.return_reasons') }}
      <span
        v-if="isMandatory"
        class="text-danger">*</span>
    </Heading>

    <Skeleton :loading="isLoading">
      <template #default>
        <div>
          <Paragraph v-t="'returns.return_reasons_description'" />

          <BFormCheckboxGroup
            v-model="selectedReturnReasons"
            v-test="'return-reasons'"
            :required="isMandatory"
            :options="returnReasonSettings.return_reasons"
            text-field="human"
            value-field="name"
            stacked />

          <Heading
            v-t="'returns.return_comment'"
            level="3" />

          <CTextArea
            v-model="newReturnComment"
            v-test="'return-comment'"
            :max-length="500" />
        </div>
      </template>

      <template #skeleton>
        <ReturnsReasonsSkeleton />
      </template>
    </Skeleton>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BFormCheckboxGroup } from 'bootstrap-vue';
import CTextArea from '@/components/forms/CTextArea';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import { RETURNS_STEP_LAST } from '@/components/returns/returns-steps/steps';
import ReturnsReasonsSkeleton from '@/components/returns/returns-reasons/ReturnsReasons.skeleton';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import Skeleton from '@/components/Skeleton';
import { isDemo } from '@/services/functions/isDemo';
import isEqual from 'lodash-es/isEqual';

export default {
  name: 'ReturnsReasons',

  components: {
    BFormCheckboxGroup,
    CTextArea,
    Card,
    Heading,
    Paragraph,
    ReturnsReasonsSkeleton,
    Skeleton,
  },

  emits: ['addHooks', 'setButtonAttributes', 'navigate'],

  data() {
    return {
      newReturnComment: null,
      selectedReturnReasons: [],
    };
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, [
      'loadingReturnComment',
      'loadingReturnReasonSettings',
      'loadingReturnReasons',
      'returnComment',
      'returnData',
      'returnReasons',
    ]),

    ...mapGetters(STORE_MODULE_RETURNS, ['returnReasonSettings']),

    /**
     * @returns {boolean}
     */
    isMandatory() {
      return Boolean(this.returnReasonSettings.mandatory);
    },

    /**
     * @returns {boolean}
     */
    isLoading() {
      return this.loadingReturnReasonSettings || this.loadingReturnReasons || this.loadingReturnComment;
    },

    /**
     * @returns {boolean}
     */
    reasonsChanged() {
      return !isEqual(this.selectedReturnReasons, this.returnReasons);
    },

    /**
     * @returns {boolean}
     */
    commentChanged() {
      return this.newReturnComment !== this.returnComment;
    },

    /**
     * Checks if user should be able to click the next button.
     *
     * @returns {boolean}
     */
    canPressNext() {
      const anyReturnReasonSelected = this.selectedReturnReasons.length > 0;
      return (!this.isMandatory || anyReturnReasonSelected) && !this.loadingReturnReasons;
    },

    /**
     * Checks if user can skip the current step.
     *
     * @returns {boolean}
     */
    canSkip() {
      return !this.isMandatory && !this.reasonsChanged && !this.commentChanged;
    },
  },

  watch: {
    '$i18n.locale'() {
      this.$nextTick().then(this.disableNextButton);
    },

    selectedReturnReasons: {
      immediate: true,
      handler() {
        this.$nextTick().then(this.disableNextButton);
      },
    },

    returnComment: {
      immediate: true,
      handler(returnComment) {
        this.newReturnComment = returnComment;
      },
    },

    returnReasons: {
      immediate: true,
      handler(returnReasons) {
        this.selectedReturnReasons = returnReasons ?? [];
      },
    },

    returnData: {
      immediate: true,
      handler(returnData) {
        if (returnData?.payment_instructions || !returnData?.email_label) {
          return;
        }

        this.$emit('navigate', RETURNS_STEP_LAST);
      },
    },
  },

  beforeMount() {
    if (isDemo()) {
      return;
    }

    // Add the onNext function to the next button click hooks
    this.$emit('addHooks', {
      clickNext: [this.onNext],
    });
  },

  methods: {
    /**
     * When the "next" button is pressed post the selected return reasons, but only if they changed.
     *
     * @returns {Promise<void[]>}
     */
    onNext() {
      return Promise.all([
        this.submitReturnReasons(),
        this.submitReturnComment(),
      ]);
    },

    /**
     * If filling in return reasons is mandatory, disable the "next" button when no return reasons are selected. If it's
     *  not mandatory and nothing is selected, change the button text to "skip".
     */
    disableNextButton() {
      this.$emit('setButtonAttributes', {
        next: {
          text: this.canSkip ? this.$t('skip') : null,
          variant: this.canSkip ? 'light' : 'primary',
          disabled: !this.canPressNext,
        },
      });
    },

    /**
     * Save, update or delete the return comment. It's never mandatory.
     *
     * - If the value is untouched, don't post.
     * - If the value changed, and it's not empty, post it.
     * - If the value changed, but it's empty, delete it.
     *
     * @returns {Promise<void>}
     */
    submitReturnComment() {
      if (!this.commentChanged) {
        return Promise.resolve();
      }

      return this.$store.dispatch(`${STORE_MODULE_RETURNS}/postReturnComment`, this.newReturnComment);
    },

    /**
     * Post or update return reasons if needed.
     *
     * @returns {Promise<void>}
     */
    submitReturnReasons() {
      if (!this.reasonsChanged) {
        return Promise.resolve();
      }

      return this.$store.dispatch(`${STORE_MODULE_RETURNS}/postReturnReasons`, this.selectedReturnReasons);
    },
  },
};
</script>
