<template>
  <div>
    <Paragraph v-skeleton="$t('returns.return_reasons_description')" />
    <FormCheckboxGroupSkeleton
      size="lg"
      :amount="5"
      stacked />
    <Heading
      v-skeleton="$t('returns.return_comment')"
      level="3" />
    <CTextArea loading />
  </div>
</template>

<script>
import CTextArea from '@/components/forms/CTextArea';
import FormCheckboxGroupSkeleton from '@/components/FormCheckboxGroupSkeleton';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';

export default {
  name: 'ReturnsReasonsSkeleton',
  components: {
    Heading,
    CTextArea,
    Paragraph,
    FormCheckboxGroupSkeleton,
  },
};
</script>
